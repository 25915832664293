import React from "react";
import { ListGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faClipboardList, faChartLine, faList, faUsers, faFileInvoiceDollar, faUser } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import './SideNav.scss';


function SideNav() {
    const history = useHistory()
    function navClick(e) {
        // e.preventDefault();
        if (e.target.id === "home-span" || e.target.parentNode.id === "home") {
            history.push(`/maincontent`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("home").classList.add("btn-active")
        } else if (e.target.id === "customerList-span" || e.target.parentNode.id === "customerList") {
            history.push(`/customerList`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("customerList").classList.add("btn-active")
        } else if (e.target.id === "attendance-span" || e.target.parentNode.id === "attendance") {
            history.push(`/attendance`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("attendance").classList.add("btn-active")
        } else if (e.target.id === "employeeList-span" || e.target.parentNode.id === "employeeList") {
            history.push(`/employeeList`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("employeeList").classList.add("btn-active");
        }

        else if (e.target.id === "transactions-span" || e.target.parentNode.id === "transactions") {
            history.push(`/transactions`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("transactions").classList.add("btn-active")
        } else if (e.target.id === "users-span" || e.target.parentNode.id === "users") {
            history.push(`/users`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("users").classList.add("btn-active")
        } else if (e.target.id === "dashboard-span" || e.target.parentNode.id === "dashboard") {
            history.push(`/dashboard`)
            if (document.getElementsByClassName("btn-active").length) {
                document.getElementsByClassName("btn-active")[0].classList.remove("btn-active");
            }
            document.getElementById("dashboard").classList.add("btn-active")
        }
    }

    return (
        <><ListGroup variant="flush">
            <ListGroup.Item><Button className="btn-active" variant="link" id="home" onClick={navClick}><FontAwesomeIcon icon={faHome} className="sidenav-icon" /><span className="sidenav-text">Home</span></Button></ListGroup.Item>
            <ListGroup.Item><Button className="btn-active" variant="link" id="customerList" onClick={navClick}><FontAwesomeIcon icon={faUsers} className="sidenav-icon" /><span className="sidenav-text">Customer List</span></Button></ListGroup.Item>
            {/* <ListGroup.Item><Button className="btn-active" variant="link" id="attendance" onClick={navClick}><FontAwesomeIcon icon={faClipboardList} className="sidenav-icon" /><span className="sidenav-text">Attendance</span></Button></ListGroup.Item>
            <ListGroup.Item><Button className="btn-active" variant="link" id="employeeList" onClick={navClick}><FontAwesomeIcon icon={faUser} className="sidenav-icon" /><span className="sidenav-text">Employee List</span></Button></ListGroup.Item>
             */}

        </ListGroup></>
    );
}

export default SideNav;
