import React, { useState } from "react";
import { Container, Form, Button, Table, Image, Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faList, faUserPlus, faCoins, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import Header from "../Layout/Header/Header";
import './Home.css';
import { useHistory } from "react-router-dom";


function Home() {
    const [headerLinks, setHeaderLinks] = useState();
    const history = useHistory();
    
    return (
        <>
            <Container fluid className="content">
                <Header menuLinks={headerLinks} />
                <div className="main-img"></div>
                <Form className="row">
                    <h4>Finance</h4>
                
                    <Form.Group className="mb-2 col-md-2 col-md-2 col-lg-2" controlId="">
                        <Form.Label style={{ width: "100%" }}>Customer List</Form.Label>
                        <Button variant={"warning"} onClick={() => {history.push(`/customerList`)}}>
                            <FontAwesomeIcon icon={faList} size="2x" />
                        </Button>&nbsp;&nbsp;
                    </Form.Group>
                    <Form.Group className="mb-2 col-md-2 col-md-2 col-lg-2" controlId="">
                        <Form.Label style={{ width: "100%" }}>Add Customer</Form.Label>
                        <Button variant={"warning"} onClick={""}>
                            <FontAwesomeIcon icon={faUserPlus} size="2x" />
                        </Button>&nbsp;&nbsp;
                    </Form.Group>
                    <Form.Group className="mb-2 col-md-2 col-md-2 col-lg-2" controlId="">
                        <Form.Label style={{ width: "100%" }}>Add Loan</Form.Label>
                        <Button variant={"warning"} onClick={""}>
                            <FontAwesomeIcon icon={faMoneyBill1Wave} size="2x" />
                        </Button>&nbsp;&nbsp;
                    </Form.Group>
                    </Form>
            </Container>
        </>
    )
}
export default Home;