import React, { useState } from "react";
import { Form, Button, Image, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faHandPointLeft, faXmark, } from '@fortawesome/free-solid-svg-icons';


function ViewCustomer(props) {

  const [input, setInput] = useState({
    custname: props.dataValues.length ? props.dataValues[0].customer_name : "",
    custcontact: props.dataValues.length ? props.dataValues[0].cont_no : "",
    addharnum: props.dataValues.length ? props.dataValues[0].addhar_no : "",
    mobileno: props.dataValues.length ? props.dataValues[0].mobileno : "",
    tempaddress: props.dataValues.length ? props.dataValues[0].address1 : "",
    district: props.dataValues.length ? props.dataValues[0].district : "",
    pincode: props.dataValues.length ? props.dataValues[0].pincode : "",
    relation: props.dataValues.length ? props.dataValues[0].relation : "",
    othrname: props.dataValues.length ? props.dataValues[0].relation_name : "",
    peraddress: props.dataValues.length ? props.dataValues[0].pre_address : "",
    tempcity: props.dataValues.length ? props.dataValues[0].district1 : "",
    picode: props.dataValues.length ? props.dataValues[0].pincode1 : "",
    wrkplace: props.dataValues.length ? props.dataValues[0].work_name : "",
    locationadd: props.dataValues.length ? props.dataValues[0].work_add : "",
    tempdist: props.dataValues.length ? props.dataValues[0].district2 : "",
    temppincode: props.dataValues.length ? props.dataValues[0].pincode2 : "",
    perlndmrk: props.dataValues.length ? props.dataValues[0].land_mark : "",
    guranteename: props.dataValues.length ? props.dataValues[0].gurantee_name : "",
    guranteemobile: props.dataValues.length ? props.dataValues[0].gurantee_no : "",

  })
 
 
  return (
    <>
      <Form className="row">
        
            <Form.Group className="mb-3 col-md-8 col-md-8 col-lg-12" controlId="custImg">
              <Form.Label style={{ width: "100%" }}>customer Image</Form.Label>
              <Form.Control
                id="custImg"
                type="file"
                readonly="true"
                name={"custImg"}
                style={{ display: "none" }}
              ></Form.Control>
              <label for="customerImage">
                <img id="target" src={''} style={{ height: "100px", width: "100px", border: "solid 3px #a5a0a0" }} onClick={""} />
              </label>
            </Form.Group>
                   
        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="custname">
          <Form.Label style={{ width: "100%" }}>Customer Name</Form.Label>
          <Form.Control type="text" name="custname" value={input.custname} defaultValue={input.custname} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="custcontact">
          <Form.Label style={{ width: "100%" }}>Customer Mobile No*</Form.Label>
          <Form.Control type="text" name="custcontact" value={input.custcontact} defaultValue={input.custcontact} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="addharnum">
          <Form.Label style={{ width: "100%" }}>Aadhar Card No</Form.Label>
          <Form.Control type="text" name="addharnum" value={input.addharnum} defaultValue={input.addharnum} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="mobileno">
          <Form.Label style={{ width: "100%" }}>WhatsApp Mobile*</Form.Label>
          <Form.Control type="text" name="mobileno" value={input.mobileno} defaultValue={input.mobileno} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-8 col-lg-8" controlId="tempaddress">
          <Form.Label style={{ width: "100%" }}>Current Address</Form.Label>
          <Form.Control type="text" name="tempaddress" value={input.tempaddress} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="district">
          <Form.Label style={{ width: "100%" }}>District</Form.Label>
          <Form.Control type="text" name="district" value={input.district} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="pincode">
          <Form.Label style={{ width: "100%" }}>Pincode</Form.Label>
          <Form.Control type="text" name="pincode" value={input.pincode} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="title">
          <Form.Label style={{ width: "100%" }}>Relation</Form.Label>
          <Form.Select type="text" name="relation" value={input.relation} defaultValue={input.relation} readonly="true" >
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Husband">Husband</option>
            <option value="Wife">Wife</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="othrname">
          <Form.Label style={{ width: "100%" }}>Relation Name</Form.Label>
          <Form.Control type="text" name="othrname" value={input.othrname} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="peraddress">
          <Form.Label style={{ width: "100%" }}>Permanent Address</Form.Label>
          <Form.Control type="text" name="peraddress" value={input.peraddress} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="tempcity">
          <Form.Label style={{ width: "100%" }}>District</Form.Label>
          <Form.Control type="text" name="tempcity" value={input.tempcity} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="picode">
          <Form.Label style={{ width: "100%" }}>Pincode</Form.Label>
          <Form.Control type="text" name="picode" value={input.picode} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="wrkplace">
          <Form.Label style={{ width: "100%" }}>Business Name</Form.Label>
          <Form.Control type="text" name="wrkplace" value={input.wrkplace} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="locationadd">
          <Form.Label style={{ width: "100%" }}>Business Add</Form.Label>
          <Form.Control type="text" name="locationadd" value={input.locationadd} readonly="true" ></Form.Control>
        </Form.Group>


        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="tempdist">
          <Form.Label style={{ width: "100%" }}>District</Form.Label>
          <Form.Control type="text" name="tempdist" value={input.tempdist} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="temppincode">
          <Form.Label style={{ width: "100%" }}>Pincode</Form.Label>
          <Form.Control type="text" name="temppincode" value={input.temppincode} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="perlndmrk">
          <Form.Label style={{ width: "100%" }}>Landmark</Form.Label>
          <Form.Control type="text" name="perlndmrk" value={input.perlndmrk} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="guranteename">
          <Form.Label style={{ width: "100%" }}>Gurantee Name</Form.Label>
          <Form.Control type="text" name="guranteename" value={input.guranteename} readonly="true" ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-4 col-lg-4" controlId="guranteemobile">
          <Form.Label style={{ width: "100%" }}>Gurantee Mobile*</Form.Label>
          <Form.Control type="text" name="guranteemobile" value={input.guranteemobile} readonly="true" ></Form.Control>
        </Form.Group>

      </Form>
    </>
  )
}
export default ViewCustomer;