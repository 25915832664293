import React, { useState } from "react";
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { BrowserRouter, Route } from 'react-router-dom';
import Header from '../Component/Layout/Header/Header'
import SideNav from '../Component/Layout/SideNav/SideNav';
import Home from "./Home/Home";
import CustomerList from "./Customer/CustomerList";
import ViewCustomer from "./Customer/ViewCustomer";


function MainContent() {
  const [headClicked, setHeadClicked] = useState(isMobile ? true : false)
  const [visiblePanel, setVisiblePanel] = useState(false)
  const [panelType, setPanelType] = useState("")
  let navPropsarr = [{ toggleIcon: faBars, profileIcon: faUser, className: "main-navbar", topFixed: "top" }]

  const [navProps, setNavProps] = useState(navPropsarr)

  const clickMenu = () => {
    setHeadClicked(!headClicked)
  }
  console.log(window.location.pathname)
  return (
    <>
      {window.location.pathname !== "/" ?
        <BrowserRouter>
          <Row className="m-0">
            <Header hideHeader={true} sideClick={clickMenu} mainProps={navProps} />
            <Col xs={3} md={3} className={headClicked ? "sidemenu collapsed" : "sidemenu "}>
              <SideNav />
            </Col>
            <Col xs={9} md={9} className={headClicked ? "main-page expand" : "main-page "}>
              <Route path="/maincontent" component={Home} />
              <Route path="/customerList" component={CustomerList} />
              <Route path="/viewCustomer" component={ViewCustomer} />
            </Col>
          </Row>
        </BrowserRouter>
        : ""}
    </>
  );
}

export default MainContent;