import Login from './Component/Login/Login';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/scss/master.scss';
import MainContent from './Component/MainContent';
import { BrowserRouter, Route } from 'react-router-dom';
import LoginTest from './Component/Login/LoginTest';



function App() {  

 console.log("project env", process.env)

  return (
    <div className="App">

      <BrowserRouter>

      <Route exact path="/" component={Login} />
        <Route path={window.location.pathname} component={MainContent} />
        
      </BrowserRouter>
   
     
    </div>
  );
}

export default App;
