import { useState, useEffect } from "react";
import { Container, Form, Button, Table, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointLeft, faSearch, faRefresh, faHome, faUserPlus, faEllipsisVertical, faEdit, faTrash, faHandHoldingDollar, faEye } from '@fortawesome/free-solid-svg-icons';
import Header from "../Layout/Header/Header";
import { useHistory } from "react-router-dom";
import "./Customer.css";
import AddCustomer from "./AddCustomer";
import ViewCustomer from "./ViewCustomer";
import axios from "axios";


function CustomerList() {
    const [input, setInput] = useState({
        customer_name: '',
        custcontact: "",
        addharnum: "",
        mobileno: "",
        tempaddress: "",
        district: "",
        pincode: "",
        relation: "",
        othrname: "",
        peraddress: "",
        tempcity: "",
        picode: "",
        wrkplace: "",
        locationadd: "",
        tempdist: "",
        temppincode: "",
        perlndmrk: "",
        guranteename: "",
        guranteemobile: "",

        finamount: "",
        interest: "",
        noofdue: "",
        dueamount: "",
        dropdown: "",
        duestrdate: "",
        interestamt: "",
        principle: ""
    })
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [panelTitle, setPanelTitle] = useState("");
    const [viewTitle, setViewTitle] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [showView, setShowView] = useState(false);
    const [editData, setEditData] = useState([]);
    const [viewData, setViewData] = useState([]);

    // Get Method....
    useEffect(() => {
        fetch('https://phpapi.jdssoftwaresolutions.com/customerlistapi.php?c=ALL')
            .then(response => { return response.json(); })
            .then(data => {
                console.log(data);
                setItems(data.custdata)
            })
        if (showAdd) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [showAdd], []);

    // Delete Method....
    const deleteCustomer = (custid) => {
        if (window.confirm("Are you sure to delete this Id " + custid + "?")) {
            fetch(`https://phpapi.jdssoftwaresolutions.com/customerlistapi.php?c=DEL&cid=${custid}`)
                .then(response => { return response.json(); })
                window.location.reload(true);
        }
    }

    const addCustomer = (items, type) => {
        setShowAdd(!showAdd);
        setPanelTitle(type);
    }
    const viewCustomer = (items, type) => {

        fetch(`https://phpapi.jdssoftwaresolutions.com/customerlistapi.php?c=VIEW&cid=${items}`)
            .then(response => { return response.json(); })
            .then(data => {
                console.log(data);
                setViewData([...data.custdata]);
                setViewTitle(type);
                setShowView(!showView);
            }, []);
    }
    const editCustomer = (items, type) => {
        fetch(`https://phpapi.jdssoftwaresolutions.com/customerlistapi.php?c=VIEW&cid=${items}`)
            .then(response => { return response.json(); })
            .then(data => {
                console.log(data);
                setEditData([...data.custdata]);
                setPanelTitle(type);
                setShowAdd(!showAdd);
            }, []);
    }

    let headerLinksarr = [
        {
            key: 0,
            name: "Add Customer",
            iconName: faUserPlus,
            onclick: addCustomer
        }
    ]

    const [headerLinks, setHeaderLinks] = useState(headerLinksarr);

    const closePanel = (value) => {
        setShowAdd(value);
        setShowView(value);

    }
    const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }


    const listData = items;
    const listItem = listData.map((list, index) =>
        <>
            <tr custid={list.custid}>
                <td style={{ textAlign: "left" }}>{index+1}</td>
                <td style={{ textAlign: "left" }}>{list.customer_name}</td>
                <td style={{ textAlign: "left" }}>{list.mobileno}</td>
                <td style={{ textAlign: "left" }}>{list.addhar_no}</td>
                <tr>
                    <td><Button variant={"light"} onClick={() => viewCustomer(list.custid)}><FontAwesomeIcon icon={faEye} size="1x" /></Button></td>&nbsp;
                    <td><Button variant={"light"} onClick={() => editCustomer(list.custid, "Update Customer")}><FontAwesomeIcon icon={faEdit} size="1x" /></Button></td>&nbsp;
                    <td><Button variant={"light"} onClick={() => deleteCustomer(list.custid)}><FontAwesomeIcon icon={faTrash} size="1x" /></Button></td>
                </tr>
            </tr>

        </>
    )

    return (
        <>
            <Container fluid className="main-content">

                {!showView ?
                    <>
                        <Header menuLinks={headerLinks} />
                        <Form className="row">
                            <h4> Search</h4>
                            <Form.Group className="mb-3 col-md-3 col-md-3 col-lg-3" controlId="custname">
                                <Form.Label style={{ width: "100%" }}>Customer Name</Form.Label>
                                <Form.Control type="text" name="custname" value={input.custname} defaultValue={input.custname} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-3 col-md-3 col-lg-3" controlId="custcontact">
                                <Form.Label style={{ width: "100%" }}>Mobile Number</Form.Label>
                                <Form.Control type="number" name="custcontact" value={input.custcontact} defaultValue={input.custcontact} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-2 col-md-2 col-md-2 col-lg-2" controlId="">
                                <Form.Label style={{ width: "100%" }}></Form.Label>
                                <Button variant={"warning"} onClick={""}>
                                    <FontAwesomeIcon icon={faSearch} size="1x" />
                                </Button>&nbsp;&nbsp;
                                <Button variant={"warning"} onClick={""}>
                                    <FontAwesomeIcon icon={faRefresh} size="1x" />
                                </Button>&nbsp;&nbsp;
                            </Form.Group>
                        </Form>
                        <Form className="row">
                            <Table responsive="sm" className="">
                                <thead>
                                    <tr>
                                        <th>Customer ID</th>
                                        <th>Customer Name</th>
                                        <th>Mobile Number</th>
                                        <th>Aadhar</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItem}
                                </tbody>
                            </Table>
                        </Form> </> :
                    <>
                        <div className="row">
                            <div className="col">
                                <h3>ViewCustomer</h3>
                            </div>
                            <div className="col">
                                <Form.Group className="mb-12 col-md-12 col-md-12 col-lg-12" controlId="">
                                    <Form.Label style={{ width: "100%" }}></Form.Label>
                                    <Button variant={"light"} onClick={() => { history.push(`/maincontent`) }}>
                                        <FontAwesomeIcon icon={faHome} size="2x" />
                                    </Button>&nbsp;&nbsp;
                                    <Button variant={"light"} onClick={() => { setShowView(false) }}>
                                        <FontAwesomeIcon icon={faHandPointLeft} size="2x" />
                                    </Button>&nbsp;&nbsp;
                                </Form.Group>
                            </div>
                        </div>


                        <ViewCustomer viewType={viewTitle} dataValues={viewData} closePanel={closePanel} />
                    </>
                }
                {showAdd ?
                 
                    <AddCustomer panelType={panelTitle} dataValues={editData} closePanel={closePanel} />
                    : ""}

            </Container >
        </>
    )
}

export default CustomerList;