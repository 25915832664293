import React, { useState } from "react";
import { Form, Button, Image, Col, Card } from "react-bootstrap";
import { faXmark, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from "moment/moment";
import Multiselect from 'multiselect-react-dropdown';
import { Panel, View } from '../shared/Shared';
import { useHistory } from "react-router-dom";


function AddCustomer(props) {

  const [input, setInput] = useState({
    custname: props.dataValues.length ? props.dataValues[0].customer_name : "",
    custcontact: props.dataValues.length ? props.dataValues[0].cont_no : "",
    addharnum: props.dataValues.length ? props.dataValues[0].addhar_no : "",
    mobileno: props.dataValues.length ? props.dataValues[0].mobileno : "",
    tempaddress: props.dataValues.length ? props.dataValues[0].address1 : "",
    district: props.dataValues.length ? props.dataValues[0].district : "",
    pincode: props.dataValues.length ? props.dataValues[0].pincode : "",
    relation: props.dataValues.length ? props.dataValues[0].relation : "",
    othrname: props.dataValues.length ? props.dataValues[0].relation_name : "",
    peraddress: props.dataValues.length ? props.dataValues[0].pre_address : "",
    tempcity: props.dataValues.length ? props.dataValues[0].district1 : "",
    picode: props.dataValues.length ? props.dataValues[0].pincode1 : "",
    wrkplace: props.dataValues.length ? props.dataValues[0].work_name : "",
    locationadd: props.dataValues.length ? props.dataValues[0].work_add : "",
    tempdist: props.dataValues.length ? props.dataValues[0].district2 : "",
    temppincode: props.dataValues.length ? props.dataValues[0].pincode2 : "",
    perlndmrk: props.dataValues.length ? props.dataValues[0].land_mark : "",
    guranteename: props.dataValues.length ? props.dataValues[0].gurantee_name : "",
    guranteemobile: props.dataValues.length ? props.dataValues[0].gurantee_no : "",

    finamount: props.dataValues.length ? props.dataValues[0].finance_amt : "",
    interest: props.dataValues.length ? props.dataValues[0].interest : "",
    noofdue: props.dataValues.length ? props.dataValues[0].no_due : "",
    dueamount: props.dataValues.length ? props.dataValues[0].due_amt : "",
    dropdown: props.dataValues.length ? props.dataValues[0].collection : "",
    duestrdate: props.dataValues.length ? props.dataValues[0].sdate : "",
    interestamt: props.dataValues.length ? props.dataValues[0].interest_amt : "",
    principle: props.dataValues.length ? props.dataValues[0].principle : "",
  })
  const [errors, setErrors] = React.useState([])
  const history = useHistory();

  const handleChange = e => {
    if (e.target.name === "custname" || e.target.name === "custcontact" || e.target.name === "addharnum"
      || e.target.name === "mobileno" || e.target.name === "tempaddress" || e.target.name === "district"
      || e.target.name === "pincode" || e.target.name === "relation" || e.target.name === "othrname"
      || e.target.name === "peraddress" || e.target.name === "tempcity" || e.target.name === "picode"
      || e.target.name === "wrkplace" || e.target.name === "locationadd" || e.target.name === "tempdist"
      || e.target.name === "temppincode" || e.target.name === "perlndmrk" || e.target.name === "guranteename"
      || e.target.name === "guranteemobile" || e.target.name === "custImg" || e.target.name === "finamount"
      || e.target.name === "interest" || e.target.name === "noofdue" || e.target.name === "actdue"
      || e.target.name === "dueamount" || e.target.name === "collection" || e.target.name === "duestrdate"
      || e.target.name === "interestamt" || e.target.name === "principle") {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const closHandler = (e) => {
    let showAdd = false;
    props.closePanel(showAdd)
  }

  const footerBtnarr = [{
    varient: "primary",
    size: "sm",
    title: "Submit",
    onClick: submitForm
  }, {
    varient: "light",
    size: "sm",
    title: "Cancel",
    onClick: closHandler
  }];

  const iconBtnarr = [{
    icon: faXmark,
    onClick: closHandler
  }]

  const panelBtn = footerBtnarr.map((btn) => (

    <Button variant={btn.varient} size={btn.size} onClick={btn.onClick}>{btn.title}</Button>
  ))

  function submitForm(e) {

    setInput({ ...input, [e.target.name]: e.target.value });
    console.log(input);
    validateForm();
  }
  const validateForm = () => {
    var errors = [];
    setErrors(errors)

    if (errors.length > 0) {
      return false;
    } else {
      let url = 'https://phpapi.jdssoftwaresolutions.com/newcustomerapi.php'
      fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(input)
      })
        .then(response => {
          console.log(response)
        })
        .then(err => {
          console.log(err);
        })
        window.location.reload(true);
    }
  }

  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  const formContent = (
    <>
      <Form className="row">

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="custname">
          <Form.Label style={{ width: "100%" }}>Customer Name</Form.Label>
          <Form.Control type="text" name="custname" value={input.custname} defaultValue={input.custname} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="custcontact">
          <Form.Label style={{ width: "100%" }}>Customer Mobile No*</Form.Label>
          <Form.Control type="text" name="custcontact" value={input.custcontact} defaultValue={input.custcontact} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="addharnum">
          <Form.Label style={{ width: "100%" }}>Aadhar Card No</Form.Label>
          <Form.Control type="text" name="addharnum" value={input.addharnum} defaultValue={input.addharnum} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="mobileno">
          <Form.Label style={{ width: "100%" }}>WhatsApp Mobile*</Form.Label>
          <Form.Control type="text" name="mobileno" value={input.mobileno} defaultValue={input.mobileno} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 pt-6" controlId="tempaddress">
          <Form.Label style={{ width: "100%" }}>Current Address</Form.Label>
          <Form.Control type="text" name="tempaddress" value={input.tempaddress} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="district">
          <Form.Label style={{ width: "100%" }}>District</Form.Label>
          <Form.Control type="text" name="district" value={input.district} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="pincode">
          <Form.Label style={{ width: "100%" }}>Pincode</Form.Label>
          <Form.Control type="text" name="pincode" value={input.pincode} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="title">
          <Form.Label style={{ width: "100%" }}>Relation</Form.Label>
          <Form.Select type="text" name="relation" value={input.relation} defaultValue={input.relation} required="true" onChange={handleChange}>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Husband">Husband</option>
            <option value="Wife">Wife</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="othrname">
          <Form.Label style={{ width: "100%" }}>Relation Name</Form.Label>
          <Form.Control type="text" name="othrname" value={input.othrname} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="peraddress">
          <Form.Label style={{ width: "100%" }}>Permanent Address</Form.Label>
          <Form.Control type="text" name="peraddress" value={input.peraddress} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="tempcity">
          <Form.Label style={{ width: "100%" }}>District</Form.Label>
          <Form.Control type="text" name="tempcity" value={input.tempcity} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="picode">
          <Form.Label style={{ width: "100%" }}>Pincode</Form.Label>
          <Form.Control type="text" name="picode" value={input.picode} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="wrkplace">
          <Form.Label style={{ width: "100%" }}>Business Name</Form.Label>
          <Form.Control type="text" name="wrkplace" value={input.wrkplace} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="locationadd">
          <Form.Label style={{ width: "100%" }}>Business Add</Form.Label>
          <Form.Control type="text" name="locationadd" value={input.locationadd} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>


        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="tempdist">
          <Form.Label style={{ width: "100%" }}>District</Form.Label>
          <Form.Control type="text" name="tempdist" value={input.tempdist} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="temppincode">
          <Form.Label style={{ width: "100%" }}>Pincode</Form.Label>
          <Form.Control type="text" name="temppincode" value={input.temppincode} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="perlndmrk">
          <Form.Label style={{ width: "100%" }}>Landmark</Form.Label>
          <Form.Control type="text" name="perlndmrk" value={input.perlndmrk} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="guranteename">
          <Form.Label style={{ width: "100%" }}>Gurantee Name</Form.Label>
          <Form.Control type="text" name="guranteename" value={input.guranteename} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="guranteemobile">
          <Form.Label style={{ width: "100%" }}>Gurantee Mobile*</Form.Label>
          <Form.Control type="text" name="guranteemobile" value={input.guranteemobile} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Col xs={6} lg={6} md={3}>

          <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="custImg">
            <Form.Label style={{ width: "100%" }}>customer Image</Form.Label>

            <Form.Control
              id="custImg"
              type="file"
              required="required"
              onChange={handleChange}
              name={"custImg"}
              style={{ display: "none" }}
            ></Form.Control>
            <label for="customerImage">
              <img id="target" src={''} style={{ height: "100px", width: "100px", border: "solid 3px #a5a0a0" }} onClick={""} />
            </label>

            {/* <div class="form-group">
          <Image className="company-logo" height="45px" width="40px" />
            </div>
              <div class="row justify-content-center pt-5">
             
                <input type="file" id="inputFile" name="custImg" onchange="readUrl(this)">
                  <button type="button" onclick="removeImg()">Close</button>
              </div> */}
          </Form.Group>
        </Col>

        <h4>Loan Details</h4>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="finamount">
          <Form.Label style={{ width: "100%" }}>Finance Amount</Form.Label>
          <Form.Control type="text" name="finamount" value={input.finamount} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="interest">
          <Form.Label style={{ width: "100%" }}>Interest</Form.Label>
          <Form.Select name="interest" value={input.interest} defaultValue={input.interest} required onChange={handleChange}>
            <option value="Daily">Daily</option>

          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="noofdue">
          <Form.Label style={{ width: "100%" }}>No Of Dues</Form.Label>
          <Form.Control type="text" name="noofdue" value={input.noofdue} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="gender">
          <Form.Label style={{ width: "100%" }}>Due Amount</Form.Label>
          <Form.Control type="text" id="actdueAmt" name="actdue" value={input.actdue} readonly="true" ></Form.Control>
          <Form.Control type="hidden" name="dueamount" value={input.dueamount} readonly="true"></Form.Control>

        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-6 col-lg-6" controlId="collection">
          <Form.Label style={{ width: "100%" }}>Collection</Form.Label>
          <Form.Select type="text" name="collection" value={input.collection} defaultValue={input.collection} required onChange={handleChange}>
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
            <option value="Bi Weekly">Bi Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="duestrdate">
          <Form.Label style={{ width: "100%" }}>Due Start Date</Form.Label>
          <Form.Control type="date" name="duestrdate" value={input.duestrdate} required="true" onChange={handleChange}></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="interestamt">
          <Form.Label style={{ width: "100%" }}>Interest</Form.Label>
          <Form.Control type="text" name="interestamt" value={input.interestamt} readonly="true"></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3 col-md-12 col-md-12 col-lg-6" controlId="principle">
          <Form.Label style={{ width: "100%" }}>Principle</Form.Label>
          <Form.Control type="text" name="principle" value={input.principle} readonly="true"></Form.Control>
        </Form.Group>
      </Form>

    </>
  )


  return (
    <div className="customer">

      <Panel panelType={props.panelType} panelFooter={panelBtn} iconBtn={iconBtnarr} panelContent={formContent} />

    </div>
    
    
    
  )
}
export default AddCustomer;