import React, { useState } from "react";
import { ListGroup, Button, Form, Image } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { MAIN_LOGO } from "../../images/images"
import './Login.scss';
import { useHistory } from "react-router-dom";
import { nameValidator, passwordValidator } from "../RegexValidator";

const changeForm = (formtype) => {
    console.log(formtype);
}
function Login() {

    const [input, setInput] = React.useState({ empEmail: "", empPass: "" });
    const [Showsignin, setShowsigin] = useState(true);
    const history = useHistory()

    const [errorMessage, seterrorMessage] = React.useState('');
    const [nameerrorMessage, setNameerrorMessage] = React.useState('');
    const [passworderrorMessage, setPassworderrorMessage] = React.useState('');


    const handleChange = e => {

        setInput({ ...input, [e.target.name]: e.target.value });

    };

    React.useEffect(() => {
        if (localStorage.getItem('auth')) history.push('/')
    }, [])

    const formSubmitter = async (e) => {
        e.preventDefault();
        // setNameerrorMessage('');
        // setPassworderrorMessage('');
        seterrorMessage('');

        // Login Get Method
        fetch(`https://phpapi.jdssoftwaresolutions.com/logindbapi.php?empEmail=${input.empEmail}&empPass=${input.empPass}`)
            .then(response => { return response.json(); })
            .then(data => {
                console.log(data)
                if (data.status === true) {
                    localStorage.setItem("logindetail", JSON.stringify(data))
                    history.push('/maincontent')
                } else {
                    seterrorMessage("Invalid Email Id and Password")
                }
            })
        };
        
        // try {
        //     let url = 'https://phpapi.jdssoftwaresolutions.com/logindbapi.php';
        //     const response = await fetch(url, {
        //         method: 'POST',
        //         mode: "no-cors",
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
        //             empEmail: input.empEmail,
        //             empPass: input.empPass
        //         }),
        //     });
        //     if (!response.ok) {
        //         alert("failed")
        //     }
        //     // Handle the response from the API as needed
        // } catch (error) {
        //     console.error('Error:', error);
        // }

        
        // try {
        //     let url = 'https://phpapi.jdssoftwaresolutions.com/logindbapi.php';

        //     const response = await fetch(url, {
        //       method: 'POST', // *GET, POST, PUT, DELETE, etc
        //       mode: 'no-cors',
        //       headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //       },
        //         body: JSON.stringify({
        //         empEmail: input.empEmail,
        //         empPass: input.empPass
        //       })
        //     })
        //     console.log(response);
        //     if (!response.ok) {
        //       throw Error(response.statusText);
        //     }
        //     console.log(response);
        //     let data = await response.json();
        //     data.empEmail = "";
        //     if (data !== undefined) {
        //         console.log("1");
        //       localStorage.setItem("logindetail", JSON.stringify(data))
        //       history.push('/maincontent')
        //     } else {
        //       alert("1")
        //     }
        //   }
        //   catch (error) {
        //     console.log("2");
        //     console.log(error);
        //     seterrorMessage("2 -- error")
        //   }
   


    return (
        <div className="login-page"><Row className="m-0"  >
            {/* <div className="login">LOGO</div> */}

            <Col lg={8} md={12} sm={12} className="login-left">

                <div className="login-btn">
                    <Button variant="secondary" onClick={() => { setShowsigin(true) }}>SignIn</Button>
                    <Button variant="secondary" onClick={() => { setShowsigin(false) }}>Register</Button>
                </div>
            </Col>
            <Col lg={4} md={12} sm={12} className="login-right">
                {Showsignin ?
                    <div className="login-form">
                        <h3>Sign In</h3>
                        {errorMessage.length > 0 && <div style={{ marginBottom: '10px', color: 'red' }}>{errorMessage}</div>}

                        <Form onSubmit={formSubmitter}>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Email Id</Form.Label>
                                {nameerrorMessage.length > 0 && <div style={{ marginBottom: '10px', color: 'red' }}>{nameerrorMessage}</div>}
                                <Form.Control type="email" size="sm" name="empEmail" placeholder="Enter the Email Id" onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                {passworderrorMessage.length > 0 && <div style={{ marginBottom: '10px', color: 'red' }}>{passworderrorMessage}</div>}
                                <Form.Control type="password" size="sm" name="empPass" placeholder="Enter the Password" onChange={handleChange} />
                            </Form.Group>

                            <div className="d-grid gap-2">
                                <Button variant="primary" size="sm" type="submit" onSubmit={formSubmitter}>Submit</Button>
                            </div>
                        </Form>
                    </div>
                    : ""}
                {!Showsignin ?
                    <div className="registor-form">
                        <h3>Register</h3>
                        <Form >
                            <span className="login100-form-title p-b-49">Login</span>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" size="sm" placeholder="Enter Name" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" size="sm" placeholder="Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" size="sm" placeholder="Password" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" size="sm" placeholder="Confirm Password" />
                            </Form.Group>
                            <div className="d-grid gap-2">

                                <Button variant="primary" size="sm" type="submit" >Submit</Button>

                            </div>
                        </Form>
                    </div>
                    : ""}
            </Col>
        </Row></div>
    );
}

export default Login;
