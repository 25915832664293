import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Image, Button, Badge, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { EMOY_LOGO } from '../../../images/images';
import './Header.scss';
import { useHistory } from "react-router-dom";

function Header(props) {

  const [toggleMenu, setToggleMenu] = useState(false)
  const { mainProps, menuLinks } = props;

  const barIcon = mainProps && mainProps.length && mainProps[0]?.toggleIcon;
  const userIcon = mainProps && mainProps.length && mainProps[0]?.profileIcon;
  const classProps = mainProps && mainProps.length && mainProps[0]?.className;
  const fixProps = mainProps && mainProps.length && mainProps[0].topFixed;
  const clickAction = (event) => {
    setToggleMenu(!toggleMenu)
    props.menuLinks[parseInt(event.currentTarget.id)].onclick(toggleMenu, event.currentTarget.innerText);
    event.preventDefault();
  }
  const menuList = menuLinks?.map((menu) => (
    <Nav.Link id={menu.key} onClick={clickAction}><FontAwesomeIcon icon={menu.iconName} /> <span className="nav-menutext">{menu.name}</span></Nav.Link>
  ))

  const history = useHistory()
  const Logout = () => {
    history.push('/')
    window.location.reload(true);

  }

  const sideToggle = () => {
    props.sideClick()
  }

  return (
    <>
      <Navbar expand="lg" fixed={fixProps} className={classProps} >
        <Container fluid>
          {barIcon ? <Button variant="link" onClick={sideToggle} ><FontAwesomeIcon icon={barIcon} className="menu-icon" /></Button> : ""}
          <Nav className="me-auto">
            {menuList}
          </Nav>

          <div className="user-icon">
            {userIcon ?
              <Dropdown align="end" title="Dropdown end" id="dropdown-menu-align-end">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {/* <FontAwesomeIcon icon={userIcon} /> */}
                  <Image className="company-logo" height="45px" width="40px" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={Logout}><FontAwesomeIcon icon={faSignOut} />&nbsp;&nbsp;<span className="sign-dropdown">Sign Out</span></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              : ""}
          </div>
        </Container>
      </Navbar>

    </>
  )
}
export default Header;